import type {
    DataObjectOptions, ItemModel, RecordSourceOptions, DataItemModel, RecordSourceCancelableEvent, ContextFilterType,
    MasterDetailsDefinition, DataObjectMetaOptions, DataObjectDeleteConfirmOptions
} from 'o365.modules.DataObject.Types.ts';
import type { EventArgType } from 'o365.modules.EventEmitter.ts';
import type { IDataHandler, IDestroyOptions, IRetrieveOptions } from 'o365.modules.DataObject.DataHandler.ts';
import type { DataObjectFieldsType } from 'o365.modules.DataObject.Fields.ts';
import type { IExportConfig } from 'o365.modules.exportData.ts';
import type { DataObjectExtensionCallback, DataObjectExtensionMethods} from 'o365.modules.DataObject.extensions.Base.ts';

// OLD CONTROLS:
import type Treeify from 'o365.modules.DataObject.Treeify.ts';
import type GroupBy from 'o365.modules.DataObject.GroupBy.ts';

import DataObjectState from 'o365.modules.DataObject.State.ts';
import DataObjectFields from 'o365.modules.DataObject.Fields.ts';
import DataObjectStorage from 'o365.modules.DataObject.Storage.ts';
import RecordSource from 'o365.modules.DataObject.RecordSource.ts';
import MasterDetails from 'o365.modules.DataObject.MasterDetails.ts';
import DynamicLoading from 'o365.modules.DataObject.DynamicLoading.ts';
import LayoutManager from 'o365.modules.DataObject.Layout.ts';
import FilterObject from 'o365.modules.FilterObject.ts';
//import SelectionControl from 'o365.modules.SelectionControl.ts';
import SelectionControl from "o365.modules.DataObject.SelectionControl.ts";
import DataItem from 'o365.modules.DataObject.Item.ts';
import { default as DataHandler, AbortError } from 'o365.modules.DataObject.DataHandler.ts';
import { userSession } from 'o365.modules.configs.ts';
import logger from 'o365.modules.Logger.ts';

import EventEmitter from 'o365.modules.EventEmitter.ts';

export default class DataObject<T extends ItemModel = ItemModel> {
    /** Property for tracking if the DataObject is initialized. */
    private _initialized = false;
    /** Id of this DataObject instance */
    id: string;
    /** App id to which this DataObject belongs to */
    appId: string;
    /** The view name used in this DataObject instance */
    viewName: string;
    /**
     * The unique table name used in this DataObject instance. 
     * If none is provided it will default to the viewName.
     */
    uniqueTable: string;
    /** Allow inserting new records in this DataObject */
    allowInsert: boolean;
    /** Allow updating records in this DataObject */
    allowUpdate: boolean;
    /** Allow deleting records from this DataObject */
    allowDelete: boolean;
    /** Show confirm dialog before deleting records  */
    deleteConfirm: boolean;
    /** Optional delete confrim options override */
    deleteConfirmOptions?: DataObjectDeleteConfirmOptions;
    /** Filter on already fetched data instead of retrieving again from database */
    clientSideFiltering: boolean;
    /** Stop attempting to save when current index changes */
    disableSaveOncurrentIndexChange: boolean;
    /** Select the first row when data is loaded */
    selectFirstRowOnLoad: boolean;
    /** Do not attempt to save changes when load is initiated */
    disableSaveOnBeforeLoad: boolean = false;
    /**
     * State of this DataObject instance. Keeps track of properties like
     * isLoaded, isLoading, rowCount, etc.
     */
    state: DataObjectState;
    /**
     * DataObject fields contains selected and all fields from the view. 
     * The selected fields array can be retrieved from `dataObject.fields.fields`, to get all
     * fields use `dataObject.fields.combinedFields`.  
     * Any field can be retrieved directly by using the fieldname as an index: `dataObject.fields[fieldName]`
     */
    fields: DataObjectFieldsType;
    /** The main storage of this DataObject instace. All of the retrieved data is stored here */
    storage!: DataObjectStorage<T>;
    /**
     * The record source acts as an interface between the DataObject and DataHandler. All options 
     * related DataObject CRUD operations are kept here.
     */
    recordSource!: RecordSource<T>;
    /**
     * Module responsible for handling master details bindings. ALl data object instances will have this. 
     * You can check if a DataObject has master set and initialized with `dataObject.masterDetails.isSet` property
     */
    masterDetails!: MasterDetails<T>;
    /** Handler responsible for CRUD operations on this DataObjet instance */
    dataHandler!: IDataHandler<T>;
    /** EventEmitter responsible for DataObject events */
    eventHandler: EventEmitter<DataObjectEvents<T>>;
    private _createNewAtTheEnd: boolean = false;
    private _softDeleteField?: string;
    /**
     * Optional storage pointer that DataObject extensions can use 
     * to provide their transformed data instead of the default storage 
     */
    private _storagePointer?: DataItemModel<T>[];
    /** Current set index */
    private _currentIndex: number | null = null;
    /** Previous set index */
    private _previousIndex: number | null = null;

    // Optional modules
    private _dynamicLoading?: DynamicLoading<T>;
    private _filterObject?: FilterObject;
    private _selectionControl?: SelectionControl<T>;
    private _layoutManager?: LayoutManager<T>;
    private _metadata?: DataObjectMetaOptions;
    private _useStream: boolean = false;
    /** Active dataObject.load() request abort controller */
    private _activeAbortController?: AbortController;
    private _emitOverrides?: {
        [P in keyof DataObjectEvents<T>]?: DataObjectEvents<T>[P]
    };
    // ----------------

    /**
     * Optional module for dynamically loading data. To enable call 
     * `dataObject.enableDynamicLoading()`
     */
    get dynamicLoading() {
        if (this._dynamicLoading == null) {
            this._dynamicLoading = new DynamicLoading(this);
        }
        return this._dynamicLoading;
    }

    get hasStoragePointer() { return !!this._storagePointer; }
    /**
     * Indicator for if this DataObject instance has DyanmicLoading module created. This does 
     * not neccecerly mean that dynamic loading is enabled.
     */
    get hasDynamicLoading() { return !!this._dynamicLoading; }
    /**
     * Optional filtering object for this DataObject instance. Is 
     * created automatically on first reference to this property.
     */
    get filterObject() {
        if (this._filterObject == null) {
            this._filterObject = new FilterObject({
                dataObject: this,
                columns: this.fields.combinedFields
            });
        }
        return this._filterObject;
    }
    /**
     * Selection control for getting and setting all selected rows and for 
     * area selection interactions. Each DataItem has `isSelected` property that can be changed direcvtly, this 
     * property marks the entire row as selected.  
     * For interacting with area selections the DataObject needs to be bound to a DataGrid or other control implementing area selections.
     */
    get selectionControl() {
        if (this._selectionControl == null) {
            this._selectionControl = new SelectionControl({
                dataObject: this,
            });
        }
        return this._selectionControl;
    }
    /** Layout manager for controling DataObject layouts */
    get layoutManager() {
        return this._layoutManager;
    }
    /** Current index on the DataObject. Can be null. */
    get currentIndex() { return this._currentIndex; }
    /** Previous index on the DataObject. Can be null. */
    get previousIndex() { return this._previousIndex; }
    /** Get the current item */
    get current() {
        if (this.currentIndex == null) {
            return undefined;
        } else {
            return this.storage.data[this.currentIndex]
        }
    }
    /** Get data array from storage or the storage pointer override */
    get data() { return this._storagePointer ?? this.storage.data; }
    /** Currently loaded rows count */
    get rowCount() { return this.state.rowCount; }
    /** Push new records in storage instead of unshifting them */
    get createNewAtTheEnd() { return this._createNewAtTheEnd; }
    set createNewAtTheEnd(value) {
        this._createNewAtTheEnd = value;
        this.storage.createNewAtTheEnd = value;
    }
    /**
     * Append last selected rows when retreiving data. This setting 
     * should be used with lookups or other controls that have `recents` implementation
     */
    get loadRecents() {
        return this.recordSource.loadRecents;
    }
    set loadRecents(value) {
        this.recordSource.loadRecents = value;
    }
    /** Field used when soft deleting. */
    get softDeleteField() { return this._softDeleteField; }
    /** Additional meta information about the dataobject */
    get metadata() { return this._metadata; }
    /**
     * When enabled retrieve requests will be done by a stream. Data will be pushed to storage in chunks and 
     * an additional event 'ChunkLoaded' will be emited during loads.
     */
    get useStream() { return this._useStream; }
    set useStream(pValue) { this._useStream = pValue; }

    constructor(pOptions: DataObjectOptions<T>, pMetaOptions?: DataObjectMetaOptions) {
        this.id = pOptions.id;
        this.appId = pOptions.appId ?? 'site';
        this._metadata = pMetaOptions;

        if (pOptions.isStaticId) {
            // TODO: Change this to a separate option, quickfix for persistent stores on register ds
            if (this._metadata == null) { this._metadata = {}; }
            this._metadata.isFromDesigner = true;
        }


        checkStoreScope(this.appId);
        if (dataObjectStores[this.appId].hasOwnProperty(this.id)) {
            throw new Error(`DataObject ${this.id} in scope ${this.appId} already exists`);
        }
        this.eventHandler = new EventEmitter();

        this.viewName = pOptions.viewName;
        this.uniqueTable = pOptions.uniqueTable || this.viewName;

        this.allowInsert = pOptions.allowInsert ?? false;
        this.allowUpdate = pOptions.allowUpdate ?? false;
        this.allowDelete = pOptions.allowDelete ?? false;

        this.deleteConfirm = pOptions.deleteConfirm ?? false;
        this._createNewAtTheEnd = pOptions.createNewAtTheEnd ?? false;
        this.clientSideFiltering = pOptions.clientSideFiltering ?? false;
        this.disableSaveOncurrentIndexChange = pOptions.disableSaveOncurrentIndexChange ?? false;
        this.selectFirstRowOnLoad = pOptions.selectFirstRowOnLoad ?? false;

        this.state = new DataObjectState();
        if (this.allowDelete || this.allowInsert || this.allowUpdate) {
            // Make sure PrimKey is always included in select fields when insert/update/delete
            // is allowed on the DataObject
            if (!pOptions.fields.some(x => x.name === 'PrimKey')) {
                pOptions.fields.push({ name: 'PrimKey' });
            }
        }
        this.fields = new DataObjectFields(pOptions.fields, pOptions.viewDefinition, pOptions.uniqueTableDefinition) as DataObjectFieldsType;
        if(this.viewName && pOptions.localizeFields){
            this.fields.translateFields(this.viewName);
        }
        (dataObjectStores[this.appId][this.id] as any) = this;
    }

    /**
     * Called right after the DataObject is created and made reactive. 
     * This ensures that there's no need to for any 'hacks' to get reactivity working on additional 
     * DataObject modules. Can be called only once per DataObject.
     */
    initializeExtensions(pOptions: DataObjectOptions<T>) {
        if (this._initialized) { return; }

        this.storage = new DataObjectStorage({
            newItemOptionsFactory: () => ({
                dataObjectId: this.id,
                appId: this.appId,
                fields: this.fields.fields,
                uniqueKeyField: this.fields.uniqueField,
                onValueChanged: (...args) => this.emit('FieldChanged', ...args),
                onSelected: (pIndex, pValue) => { this.emit('ItemSelected', pIndex, pValue); this.selectionControl.onSelection(pIndex, pValue); }
            }),
            createNewAtTheEnd: this._createNewAtTheEnd,
            fields: this.fields.fields,
        });
        this.recordSource = new RecordSource(pOptions, this);

        if (DataObject.prototype.hasOwnProperty('offline')) {
            pOptions.disableLayouts = true;
        }

        if (userSession.personId != null && !pOptions.disableLayouts && !window.hasOwnProperty('af')) {
            try {
                this._layoutManager = new LayoutManager(this);
            } catch (ex) {
                logger.warn(`Failed to create layout manager for ${this.appId}_${this.id}:\n`, ex);
            }
        }

        this.masterDetails = new MasterDetails();
        this.masterDetails.initialize(pOptions, this);
        if (pOptions.dynamicLoading) {
            this.dynamicLoading.enabled = true;
        }
        if (pOptions.dataHandler) {
            this.dataHandler = pOptions.dataHandler;
            if (this.dataHandler.setDataObject) {
                this.dataHandler.setDataObject(this);
            }
        } else {
            this.dataHandler = new DataHandler(this);
        }

        if (pOptions.enableProperties) {
            this.hasPropertiesData = true;
            import('o365.modules.DataObject.extensions.PropertiesData.ts').then(() => {
                this.propertiesData.initialize();
            });
        }
        if(pOptions.filterString){
            this.filterObject.applyInitFilter(pOptions.filterString,false);
        }
        this._initialized = true;
    }

    /**
     * Attach an event listener on the data object.
     * Returns a cancel function, when called it will remove the listener.
     */
    on<K extends keyof DataObjectEvents<T>>(event: K, listener: DataObjectEvents<T>[K]) {
        return this.eventHandler.on(event, listener);
    }
    /**
     * Attach an event listener on the data object that will fire only once.
     * Returns a cancel function, when called it will remove the listener.
     */
    once<K extends keyof DataObjectEvents<T>>(event: K, listener: DataObjectEvents<T>[K]) {
        return this.eventHandler.once(event, listener);
    }
    /** Detach an event listener from the DataObject */
    off<K extends keyof DataObjectEvents<T>>(event: K, listener: DataObjectEvents<T>[K]) {
        return this.eventHandler.off(event, listener);
    }
    /** Emit DataObject event to all of the listeners on this DataObject */
    emit<K extends keyof DataObjectEvents<T>>(event: K, ...args: EventArgType<DataObjectEvents<T>, K>) {
        if (this._emitOverrides && this._emitOverrides[event]) {
            // @ts-ignore
            return this._emitOverrides[event](...args);
        } else {
            return this.eventHandler.emit(event, ...args);
        }
    }
    /** Remove all event listeners from this DataObject */
    removeAllListeners = () => this.eventHandler.removeAllListeners();

    /**
     * Set the current index for this DataObject
     * @param pIndex new current index
     * @param pForceSet skip index checks and run all of the 
     * index change logic even if current inddex is equal to the provided one
     */
    setCurrentIndex(pIndex: number, pForceSet = false) {
        if ((pIndex == undefined) || (!pForceSet && pIndex === this.currentIndex)) { return; }
        this.storage.data.forEach(x => {
            if (x.current) { x.current = false; }
        });

        this.emit('CurrentIndexChanging', this.previousIndex, pIndex);
        if (!this.disableSaveOncurrentIndexChange) { this.save(); }
        if (this.storage.data[pIndex]) {
            this.storage.data[pIndex].current = true;
        }
        this._previousIndex = this.currentIndex;
        this._currentIndex = pIndex;
        this.emit('CurrentIndexChanged', this.previousIndex, pIndex);

        if (this.current && !this.current?.isNewRecord) {
            this.masterDetails.loadDetailDataObjects();
        }
    }

    /**
     * Update the current index value without running any side effects. 
     * This function should only be used by system moudles/controls.
     */
    updateCurrentIndex(pIndex: number) {
        this._currentIndex = pIndex;
    }

    /**
     * Set the current index to the next index from the given one, if no
     * item exists will attempt to use the previous index. In cases there are no next or 
     * previous indexes, the current index will be unset.
     */
    setCurrentIndexNext(pIndex?: number) {
        if (pIndex == null) {
            pIndex = this.currentIndex ?? undefined;
        }
        if (pIndex == null) { return; }
        if (this.storage.data[pIndex + 1]) {
            this.setCurrentIndex(pIndex + 1, true);
        } else if (this.storage.data[pIndex - 1]) {
            this.setCurrentIndex(pIndex - 1, true);
        } else {
            this.unsetCurrentIndex();
        }
    }


    /**
     * Set the current index to null value
     * and clear connected details data
     */
    unsetCurrentIndex() {
        if (this.current?.current === true) { this.current.current = false; }
        this._previousIndex = null;
        this._currentIndex = null;
        this.masterDetails.clearAllDetailDataObjects();
    }

    /**
     * Cancel all changes or for a speciffic item. 
     * Changes can also be canceled directly from an item with `item.cancelCahnges()`
     */
    cancelChanges(pIndex?: number, pKey?: keyof T & string) {
        const changes = [];
        if (pIndex == null) {
            this.storage.changes.forEach(item => { item.cancelChanges(pKey); changes.push(item) });
        } else {
            const item = this.storage.data[pIndex];
            item.cancelChanges(pKey);
            if (!item.hasChanges && item?.isNewRecord) {
                this.remove(pIndex);
                if (this.state.rowCount) {
                    this.state.rowCount -= 1;
                }
                if (this._dynamicLoading) {
                    this._dynamicLoading.dataLoaded(this.storage.data);
                }
            }
            changes.push(item);
        }
        this.emit('ChangesCancelled', changes);
    }

    /**
     * Load the DataObject
     * @param pParams optional request option overrides
     * @param pThrowError when `true` will re-trhow the rejected promises instead of logging them'
     * into console
     */
    async load(pParams?: RecordSourceOptions, pReThrowError = false) {
      
        if (pParams?.skip && pParams.skip > 0) {
            this.state.isNextPageLoading = true;
        }
        if (!this.state.isNextPageLoading) {
            this.state.isLoading = true;
        }
        if (!this.disableSaveOnBeforeLoad && !this.state.isNextPageLoading && this.storage.hasChanges) {
            try {
                await this.save();
            } catch (ex) {
                logger.error(ex);
                this.storage.cancelChanges();
            }
        }

        if (this.masterDetails.isSet) {
            const masterRowExists = await this.masterDetails.resolveMasterRow();
            if (!masterRowExists) {
                this.state.isLoading = false;
                this.unsetCurrentIndex();
                return Promise.resolve([]);
            }
        }

        let options: RecordSourceOptions & RecordSourceCancelableEvent & Partial<IRetrieveOptions<T>> = this.recordSource.getOptions();

        if (pParams) { options = { ...options, ...pParams }; }
        
        this.emit('BeforeLoad', options);
        
        if (options.cancelEvent === true) {
            this.state.isLoading = false;
            return;
        }
        
        try {
            let storageData: Array<DataItemModel<T>> = [];
            
            let clearStorage: boolean | undefined = options.clearStorage ?? options.skip === 0;

            if (this._useStream) {
                let vSkip = options.skip ?? 0;

                if (clearStorage) {
                    this.storage.clearItems();
                }

                options.onChunkLoaded = (chunkResult: T[]) => {
                    const chunk = this.storage.setItems(chunkResult, undefined, vSkip);
                    if (this._dynamicLoading) {
                        this._dynamicLoading.dataLoaded(chunk, { ...options, skip: vSkip, maxRecords: chunk.length });
                    }
                    vSkip += chunkResult.length;
                    this.emit('ChunkLoaded', chunk);
                }
            }

            if (this._activeAbortController) {
                this._activeAbortController.abort();
                this._activeAbortController = undefined;
            }

            options.getAbortController = (pAbortController) => this._activeAbortController = pAbortController;
            if(this.recordSource.savingPromise){
                await this.recordSource.savingPromise;
            }
            const data = await this.dataHandler.retrieve(options);
            
            if (!this._useStream) {
                const dataIsInStorage = data.length > 0 && data.every(item => item instanceof DataItem);

                storageData = dataIsInStorage
                    ? data as DataItemModel<T>[]
                    : this.storage.setItems(data, clearStorage, options.skip);
            } else {
                storageData = this.storage.items.slice(options.skip ?? 0, (options.skip ?? 0) + data.length);
            }

            if (data.length === 0 && (options.skip === 0 || options.skip == null)) {
                
                    this.unsetCurrentIndex()
             
            } else if (this.selectFirstRowOnLoad) {
                if (options.skip === 0 || options.skip == null) {
                    this.setCurrentIndex(0, true);
                }
            } else {
                if (options.skip === 0 || options.skip == null) {
                    this.unsetCurrentIndex();
                }
            }
            this.state.isLoading = false;
            this.state.isLoaded = true;

            if (this.state.isNextPageLoading) {
                this.state.isNextPageLoading = false;
            }

            this.recordSource.updateRowCount(options);

            if (this._dynamicLoading) {
                this._dynamicLoading.dataLoaded(storageData, options);
            }

            this.recordSource.updatePreviousWhereClause();

            this.emit('DataLoaded', storageData, options);
            this._activeAbortController = undefined;
            return storageData;
        } catch (ex) {
            if (ex instanceof AbortError) {
                logger.log('Request aborted');
                return;
            }
            this.state.isLoading = false;
            if (pReThrowError) { throw ex; }

            logger.error(this.id, ex);
            // this.state.isLoading = false;
            this._activeAbortController = undefined;
            return Promise.resolve([]);
        }
    }

    /**
     * Save all records with changes. 
     * If index provided will only attempt to save item with that index.
     */
    async save(pIndex?: number) {
        return this.recordSource.save(pIndex);
    }

    /** Remove item from storage without deleting it from the database */
    remove(pIndex?: number) {
        if (pIndex == null) {
            pIndex = this.currentIndex ?? undefined;
        }
        if (pIndex == null) { return; }
        const storageLength = this.storage.data.length;
        const item = this.storage.data[pIndex];
        this.storage.removeItem(pIndex);
        if (storageLength > this.storage.data.length) {
            if (item.isSelected) {
                item.isSelected = false;
            }
            if (this.state.rowCount) {
                this.state.rowCount -= 1;
            }
            this.updateCurrentIndexAfterItemRemove(item, pIndex);
        }
        if (this._dynamicLoading) {
            this._dynamicLoading.dataLoaded(this.storage.data, { skip: 0});
        }
    }

    /**
     * Send a request to delete an item at the given index. 
     * If no index given then will use the current set one.
     */
    async delete(pIndex?: number | null, pItem?: DataItemModel<T> & { o_DeleteConfirm?: boolean }) {
        return this.recordSource.delete(pIndex, pItem);
    }

    /**
     * Set item as deleted by index
     * If no index is provided will use current index
     */
    async softDelete(pIndex?: number, pItem?: DataItemModel<T> & { o_DeleteConfirm?: boolean }) {
        return this.recordSource.softDelete(pIndex, pItem);
    }

    /**
     * Send a request to delete a DataItem from the database. 
     * If no item provided will attempt to use current one.
     */
    deleteItem(pItem?: DataItemModel<T>) {
        return this.recordSource.deleteItem(pItem);
    }

    /** Set item as deleted */
    softDeleteItem(pItem?: DataItemModel<T>) {
        return this.recordSource.softDeleteItem(pItem);
    }

    /** Set field that should be updated when soft deleting */
    setSoftDeleteField(pField: string) {
        this._softDeleteField = pField;
    }

    /**  
     * Get all fields or a specific one.   
     * Compatability function: same functionality can be achieved by
     * `dataObject.fields.fields` or `dataObject.fields[pField]` 
     */
    getFields(pField?: string) {
        if (pField) {
            return this.fields[pField];
        } else {
            return this.fields.fields;
        }
    }

    /**
     * Create new record. If item values are provided and `pSetCurrentIndex` is true, 
     * then keep in mind that if `disableSaveOncurrentIndexChange` is `false` the newly created record will 
     * attempt to save itself to the database 
     */
    createNew(pOptions?: T & { current?: boolean }, pSetCurrentIndex = true, pAppendRowCount = true) {
        const item = this.storage.createNew(pOptions);

        if (this.state.rowCount != null && pAppendRowCount) {
            this.state.rowCount += 1;
        }

        if (pSetCurrentIndex || item.current) {
            this.setCurrentIndex(item.index, true);
        }
        if (this._dynamicLoading) {
            this._dynamicLoading.setItems(this.storage.data, 0);
            this.emit('DynamicDataLoaded', false, [item]);
            if (this._dynamicLoading.onDataLoaded) {
                this._dynamicLoading.onDataLoaded(true);
            }
        }
        return item;
    }

    /** Enable dynamic loading for this DataObject */
    enableDynamicLoading() {
        this.dynamicLoading.enabled = true;
        this.dynamicLoading.setItems(this.storage.data);
    }

    /** Set the storage pointer override for DataObject.data */
    setStoragePointer(pPointer: DataItemModel<T>[] | undefined) {
        this._storagePointer = pPointer;
    }

    /** Enable URL filter for this DataObject */
    enableUrlFilter() {
        this.filterObject.enableUrlFilter();
    }

    /**
     * Enable client side filtering for this DataObject instance. 
     * The DataHandler must have `setClientSideData` function implemented.
     */
    enableClientSideHandler(pData?: T[]) {
        if (this.dataHandler.setClientSideData) {
            this.clientSideFiltering = true;
            if (pData) {
                this.state.isLoaded = true;
                this.dataHandler.setClientSideData(pData);
            }
        }
    }

    /**
     * Enable context filter for data object. Wil reload data object when context changes also will include IDPAth in where clause if defined
     * @param {object} pOptions - Defaults to {idPathField:"IdPath"} when it is undefined. Null will not set ID Path, it will just reload.
     */
    enableContextFilter(pContextFilter?: ContextFilterType | null, pOptions: {
        autoLoad?: boolean
    } = { autoLoad: true }) {
        this.recordSource.autoLoadOnContextChange = pOptions.autoLoad ?? true;

        if (pContextFilter === null) {
            this.recordSource.setContextFilter(null);
        } else if (typeof pContextFilter == 'function') {
            this.recordSource.setContextFilter(pContextFilter)
        } else {
            this.recordSource.setContextFilter({
                idPathField: pContextFilter ? pContextFilter['idPathField'] : (this.fields['OrgUnitIdPath'] ? 'OrgUnitIdPath' : 'IdPath')
            });
        }
    }

    /** Removes context filtering  */
    disableContextFilter() {
        this.recordSource.setContextFilter(null);
    }

    /** Execute a data search function */
    async dataSearch(pSearchString: string, pSearchFunction: string) {
        if (pSearchString) {
            this.recordSource.searchFunction = pSearchFunction;
            this.recordSource.searchString = pSearchString;
        } else {
            this.recordSource.searchFunction = undefined;
            this.recordSource.searchString = undefined;
        }
        return this.load();
    }

    /** Clean up modules added by this DataObject and remove it from storage */
    destroyDataObject() {
        this.eventHandler.removeAllListeners();
        this._layoutManager?.destroy();
        delete dataObjectStores[this.appId][this.id];
    }

    // --- HELPER METHODS ---

    /**
     * Try to set the current index by PrimKey. 
     * If the item is found its index will be returned.
     */
    setCurrentIndexByPrimKey(pKey: string) {
        const item = this.storage.data.find(x => x.primKey === pKey);
        if (item) {
            this.setCurrentIndex(item.index, true);
        }
        return item?.index;
    }

    /**
     * Refreshes the data for the specified row.
     * 
     * @param {number} [pIndex] The index of the row to refresh. If not provided, it will use the index of the currently selected row.
     * @returns The result of refreshing the row. If everything is successful, this will be the row
     */
    refreshRow(pIndex?: number) {
        return this.recordSource.refreshRow(pIndex);
    }

    /**
     * Helper function for moving DataItem to a new index in storage.
     * 
     * @param {number} [pItemIndex] Index of the item to move
     * @param {number} [pTargetIndex] New index of the item
     */
    reshiftItem(pItemIndex: number, pTargetIndex: number) {
        arrayMove(this.storage.data, pItemIndex, pTargetIndex);
        this.storage.reindex();
        if (this.hasDynamicLoading) {
            this.dynamicLoading.dataLoaded(this.data, { skip: 0 });
        }
    }

    /** @depricated */
    // @ts-ignore
    loadNextPage(pOptions: RecordSourceOptions) {
        // use dataObject.dynamicLoading.loadNextPage
        return this.dynamicLoading.loadNextPage(pOptions);
    }

    /** Set new master details bindings */
    setMasterDetails(pMasterDetailDefinition: MasterDetailsDefinition[], pMasterObjectId: string) {
        this.masterDetails.setMasterDetails({
            masterDetailDefinition: pMasterDetailDefinition,
            masterDataObject_ID: pMasterObjectId
        });
    }

    /**
     * Update current index based on the removed item
     * @param pItem Removed item
     * @param pIndex Removed item index, when not provided will try to use
     */
    updateCurrentIndexAfterItemRemove(pItem: DataItemModel<T>, pIndex?: number) {
        const index = pIndex ?? pItem.index;
        if (pItem?.current) {
            if (this.data[index] != null) {
                this.setCurrentIndex(index, true);
            } else if (index - 1 >= 0 && this.data[index - 1] != null) {
                this.setCurrentIndex(index - 1, true);
            } else {
                this.unsetCurrentIndex();
            }
        } else if (this.currentIndex != null && this.currentIndex > index) {
            // Items shifted, update current index without state changes
            this.updateCurrentIndex(this.currentIndex - 1);
        }
    }

    /**
     * Data should be retrieved through `dataObject.data` property
     * @deprecated
     */
    // @ts-ignore
    private getData() { return this.data; }
    /**
     * Data length should be retrieved through `dataObject.data.length`
     * @depricated
     */
    // @ts-ignore
    private getDataLength() { return this.data.length; }
    /** @depricated */
    // @ts-ignore
    private setData(_pData: any, _pClear: any) {
        // use dataObject.storage.setData
        this.storage.setItems(_pData, _pClear);
    }

    /** @depricated */
    // @ts-ignore
    private updateItem(_pIndex: number, _pValue: any) {
        // use dataObject.storage.updateItem
        this.storage.updateItem(_pIndex, _pValue);
    }

    /** @depricated */
    // @ts-ignore
    private retrieve(pOtions: any) {
        // use dataObject.recordSource.retrieve
        return this.recordSource.retrieve(pOtions);
    }

    /** @depricated */
    // @ts-ignore
    private createNewRecord(pOptions?: T & { current?: boolean }) {
        logger.warn('Change to createNew');
        // use dataObject.createNew
        return this.createNew(pOptions);
    }

    // --- MODULES TO BE REPLACED ---
    treeify?: Treeify<T>;
    async enableTreeify(options: {
        idField?: string,
        parentField?: string,
        idPathField?: string
    }): Promise<Treeify<T> | null> {
        if (this.treeify) {
            return this.treeify;
        }

        try {
            const TreeifyModule = await import('o365.modules.DataObject.Treeify.ts');
            this.treeify = new TreeifyModule.default(this, options);
            this.treeify.enable();
            return this.treeify;
        } catch (ex) {
            logger.error(ex);
            return null;
        }
    }

    groupBy?: GroupBy;
    async enableGroupBy(pOptions: any, clientSide = false): Promise<GroupBy | null> {
        if (this.groupBy) {
            return this.groupBy;
        }

        try {
            const moduleUrl = clientSide
                ? 'o365.modules.DataObject.GroupBy.Clientside.ts'
                : 'o365.modules.DataObject.GroupBy.ts';
            const GroupByModule = await import(moduleUrl);
            this.groupBy = new GroupByModule.default({
                dataObject: this,
                setupOptions: pOptions
            });
            this.groupBy!.postCreateInit();
            return this.groupBy!;
        } catch (ex) {
            logger.error(ex);
            return null;
        }
    }

    /**
     * Returns execution callback for DataObject extensions. Used for functions that should only be executed from
     * extensinos. 
     */
    protected _getExtensionCallback(): DataObjectExtensionCallback<T, keyof DataObjectExtensionMethods<T>> {
        return (pKey, ...args) => {
            switch (pKey) {
                case 'overrideEmit':
                    const [pEvent, pOverride] = args;
                    if (this._emitOverrides == null) { this._emitOverrides = {} }
                    // @ts-ignore
                    this._emitOverrides[pEvent] = pOverride
                    return;
                case 'clearEmitOverride':
                    if (this._emitOverrides) {
                        delete this._emitOverrides[args[0]];
                        if (Object.keys(this._emitOverrides).length) {
                            delete this._emitOverrides;
                        }
                    }
                    return;
                default:
                    throw new TypeError(`${pKey} method is not allowed`);
            }
        }
    }
}

/** Raw data object stores for each app in the current window */
const dataObjectStores: Record<string, Record<string, DataObject>> = {};
/** Helper function for ensuring that the current app scope exists in the raw data object stores */
function checkStoreScope(pScope = 'site') {
    if (!dataObjectStores[pScope]) { dataObjectStores[pScope] = {}; }
}
/** Helper function for moving items in an array */
function arrayMove<T>(pArray: (T | undefined)[], pOldIndex: number, pNewIndex: number) {
    if (pNewIndex >= pArray.length) {
        let k = pNewIndex - pArray.length + 1;
        while (k--) {
            pArray.push(undefined);
        }
    }
    pArray.splice(pNewIndex, 0, pArray.splice(pOldIndex, 1)[0]);
}

declare global {
    interface Window {
        /** Disable the onbeforeunolad handler for DataObjects in this window context */
        __o365_allowLeavingWithChanges?: boolean;
    }
}

if (!window.onbeforeunload) {
    window.onbeforeunload = function (event) {
        if (self.__o365_allowLeavingWithChanges) { return; }
        let hasChanges = false;
        Object.values(dataObjectStores).forEach(scope => {
            Object.values(scope).forEach(ds => {
                if (ds.storage.hasChanges) {
                    hasChanges = true;
                }
            });
        });
        if (hasChanges) {
            event.preventDefault();
        }
    }
}

export type DataObjectEvents<T extends ItemModel> = {
    'FieldChanged': <K extends keyof T>(field: K, newValue: T[K], oldValue: T[K], currentValues: T, row: DataItemModel<T>) => void
    'ChangesCancelled': (rows: DataItemModel<T>[]) => void
    'BeforeDelete': (options: RecordSourceOptions & RecordSourceCancelableEvent, row: DataItemModel<T>) => void
    'AfterDelete': (options: RecordSourceOptions, row: DataItemModel<T>) => void
    'BeforeSoftDelete': (options: RecordSourceOptions & RecordSourceCancelableEvent, row: DataItemModel<T>) => void
    'AfterSoftDelete': (options: RecordSourceOptions, row: DataItemModel<T>) => void
    'CurrentIndexChanging': (prevIndex: number | null, newIndex: number) => void
    'CurrentIndexChanged': (prevIndex: number | null, newIndex: number) => void
    'BeforeLoad': (options: RecordSourceOptions & RecordSourceCancelableEvent) => void
    'BeforeRowCountLoad': (options: RecordSourceOptions & RecordSourceCancelableEvent) => void
    'DataLoaded': (data: DataItemModel<T>[], options: RecordSourceOptions) => void
    'AfterSave': (options: RecordSourceOptions & { operation: 'create' | 'update' }, item: T, dataItem: DataItemModel<T>) => void
    'BeforeSave': (options: RecordSourceOptions & RecordSourceCancelableEvent, item: T, dataItem: DataItemModel<T>) => void
    'BeforeUpdate': (options: RecordSourceOptions & RecordSourceCancelableEvent, item: T) => void
    'BeforeCreate': (options: RecordSourceOptions & RecordSourceCancelableEvent, item: T) => void
    'DynamicDataLoaded': (pClear: boolean, pNewData: DataItemModel<T>[]) => void;
    'BeforeBulkDelete': (options: RecordSourceOptions & RecordSourceCancelableEvent & IDestroyOptions, items: DataItemModel<T>[]) => void;
    'BeforeExport': (pExportConfig: IExportConfig) => void;
    'BeforeExportTemplate': (pTemplateType: 'Update'|'Import', pExportConfig: IExportConfig) => void;
    'BeforeImportTemplate': (pExportConfig: any) => void;
    'SummaryItemLoaded': (pItem: Partial<T>) => void;
    'SortOrderChanged': () => void;
    'ChunkLoaded': (pData: DataItemModel<T>[]) => void;
    'ItemSelected': (pIndex: number, pValue: boolean) => void;
    'LayoutApplied': () => void;
};

export { DataObject };
